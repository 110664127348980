import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'luxon'
})
export class LuxonPipe implements PipeTransform {

  transform(date: string | Date, zone: string = 'UTC+12'): unknown {
    date = date.toString();
    let d: DateTime = DateTime.fromISO(date, {zone: zone});
    return d.toLocal().toFormat('dd/MM/y, h:mm:ss a');
  }

}
