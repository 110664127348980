<div class="content">
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let group of groups | keyvalue; first as isFirst" [expanded]="isFirst">
            <!-- HEADER -->
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{group.value.name}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- BODY -->
            <mat-divider></mat-divider>
            <ng-template matExpansionPanelContent>
                <app-campaigns [group]="group.value"></app-campaigns>
            </ng-template>
            <!-- END -->
        </mat-expansion-panel>
    </mat-accordion>
</div>