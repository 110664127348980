<div *ngIf="userData" class="user">
    <div [class.deleted]="deleted">
        {{userData?.displayName}}
    </div>
    <div [class.deleted]="deleted">
        {{userData?.email}}
    </div>
    <div [class.deleted]="deleted">
        <mat-checkbox [disabled]="deleted || disabled" [checked]="admin" (change)="toggleAdmin($event)"></mat-checkbox>
    </div>
    <button *ngIf="!deleted && !disabled" (click)="deleteGroupUser()" mat-icon-button>
        <mat-icon>delete</mat-icon>
    </button>
    <button *ngIf="deleted && !disabled" (click)="deleteGroupUser(true)" mat-icon-button>
        <mat-icon>restore_from_trash</mat-icon>
    </button>
    <button *ngIf="!deleted && disabled" disabled mat-icon-button>
        <mat-icon>block</mat-icon>
    </button>
</div>
<div *ngIf="!userData">
    Loading User...
</div>
