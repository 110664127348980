import { Component, OnInit, OnDestroy } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Recipient } from 'src/app/models/reporting/recipient';
import { Event } from 'src/app/models/reporting/event';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss']
})
export class StoryComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  recipient!: Recipient;
  events!: Event[];
  _events!: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public parentData: any, private data: DataService) {
    this.recipient = parentData?.recipient;
  }

  ngOnInit(): void { 
    this._events = this.data.getEvents().subscribe(
      res => { this.events = res; },
      e => {},
      () => { this.loading = false; }
    );
  }

  ngOnDestroy(): void {
    this._events?.unsubscribe();
  }
}
