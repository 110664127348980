import { Component, OnInit } from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DataService } from 'src/app/services/firebase/data.service';
import { SettingsService } from 'src/app/services/website/settings.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  year: number;
  _auth: AuthService;
  _data: DataService;

  constructor(private auth: AuthService, private data: DataService, private settings: SettingsService) {
    this.year = new Date().getFullYear();
    this._auth = auth;
    this._data = data;
  }

  ngOnInit(): void {
  }

  signOut() {
    this.auth.SignOut();
  }

  version(): string {
    return this.settings.getVersion();
  }


  mode: MatDrawerMode = 'side';
  opened = true;

  onResize(event: any) {
    const width = event.target.innerWidth;

    if (width < 760) {
      this.mode   = 'push';
      this.opened = false;
    } else {
      this.mode   = 'side';
      this.opened = true;
    }
  }

  toggleOpen() {
    this.opened = !this.opened;
  }

}
