<!-- CLASSIFICATION BADGE - Shows if the persons status is Sent/Delivered/Hard Bounced/Opened/Clicked/Unsubscribed/Reported -->
<div id="classification">
    
</div>

<!-- IDENTITY - Shows the primary identifier used for the persons reporting identity -->
<div id="identity">
    <h1>{{recipient.Address || "Unknown Address"}}</h1>
</div>

<!-- DETAILS - Shows a list of dynamic data saved in reporting -->
<div id="details">
    <h3>Details</h3>
    <div *ngFor="let datum of recipient.Data | keyvalue" style="width:100%;"><b>{{datum.key}}</b>&nbsp;<span style="float: right;">{{datum.value}}</span></div>
</div>

<!-- TIMELINE - Shows an event timeline for the person -->
<div id="timeline" style="padding-top: 1em;">
    <h3>Timeline</h3>
    <ng-template [ngIf]="!loading">
        <div *ngFor="let event of events" style="width:100%; justify-content: center;"><span>{{event.type}}</span><span style="float: right;">{{(event.time || "") | luxon}}</span></div>
    </ng-template>
    <ng-template [ngIf]="loading">
        <div style="display: flex; justify-content: center; align-content: center; width: 100%;">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>

<!--
<mzd-timeline>
    <mzd-icon><fa-icon [icon]="fa-envelope"></fa-icon></mzd-icon>
    <mzd-timeline-content [border]="true">
        Your HTML code or any angular component can be placed here.
        Content will be displayed in a card.
    </mzd-timeline-content>
</mzd-timeline>
-->