import { firestore } from "firebase";

export class Link {
    snapshot?: firestore.DocumentSnapshot;

    Id: string | undefined;
    URL: string | undefined;
    Click: string | undefined;

    constructor(init: Partial<Link> | Link | undefined, snapshot?: firestore.DocumentSnapshot) {
        Object.assign(this, init);

        if (snapshot) {
            this.snapshot = snapshot
            if (!init?.Id) this.Id = this.snapshot.id;
        }
    }
}