<mat-card class="content-card">
    <div class="settings">
        <mat-tab-group class="settings-tabs" selectedIndex="1">
            <mat-tab label="Account"> 
                <ng-template mat-tab-label>
                    <mat-icon>person</mat-icon>&nbsp;Account
                </ng-template>
                <ng-template matTabContent>
                    <app-construction></app-construction>
                </ng-template>
            </mat-tab>
            <mat-tab label="Groups">
                <ng-template mat-tab-label>
                    <mat-icon>group</mat-icon>&nbsp;Groups
                </ng-template>
                <ng-template matTabContent>
                    <div class="tab-padding">
                        <app-group></app-group>
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template label="Billing" mat-tab-label>
                    <mat-icon>payments</mat-icon>&nbsp;Billing
                </ng-template>
                <ng-template matTabContent>
                    <app-construction></app-construction>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template label="API" mat-tab-label>
                    <mat-icon>code</mat-icon>&nbsp;API
                </ng-template>
                <ng-template matTabContent>
                    <app-construction></app-construction>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</mat-card>