import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/models/firebase/group';
import { Report } from 'src/app/models/firebase/report';
import { DataService } from 'src/app/services/firebase/data.service';
import { StorageService } from 'src/app/services/firebase/storage.service';

@Component({
  selector: 'app-download-list',
  templateUrl: './download-list.component.html',
  styleUrls: ['./download-list.component.scss']
})
export class DownloadListComponent implements OnInit, OnDestroy {
  loading = true;
  @Input() group!: Group;

  _reports!: Subscription;
  reportsReady!: Report[];
  reportsPending!: Report[];

  constructor(private data: DataService, public storage: StorageService) { }

  ngOnInit(): void {
    this._reports = this.data.getReports(this.group).subscribe( res => { 
      this.reportsReady = res.filter(r => r.status == 'Ready'); 
      this.reportsPending = res.filter(r => r.status != 'Ready'); 
      this.loading = false; 
    } );
  }

  ngOnDestroy(): void {
    this._reports.unsubscribe();
  }

}
