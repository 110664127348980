import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SettingsService } from 'src/app/services/website/settings.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  _auth: AuthService;
  year: number;

  constructor(private router: Router, private auth: AuthService, private settings: SettingsService) { 
    this._auth = this.auth;
    this.year = new Date().getFullYear();
  }

  ngOnInit(): void {
    
  }

  version(): string {
    return this.settings.getVersion();
  }
}