import { Component, OnInit, Input } from '@angular/core';
import { curveMonotoneX } from 'd3-shape';
import { database } from 'firebase';

@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.scss'],
})
export class InteractionsComponent implements OnInit {
  @Input() data: any;

  percentage = (x: number, y: number, r: number): number => { 
    return  ( Math.round( ((x||0) / (y||1)) * Math.pow(10, Math.max(r + 2, 2)) ) / Math.pow(10, Math.max(r, 0) ) );
  }

  public cardStats: any = [
    
  ];

  public graphData: any = [
    {
      "name": "Opens",
      "series": []
    },
    {
      "name": "Clicks",
      "series": []
    }
  ];
  
  // options
  curve: any = curveMonotoneX;
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  yAxisMin: number = 0;
  xAxisLabel: string = "Date"
  yAxisLabel: string = "# of Emails";
  autoScale: boolean = false;
  timeline: boolean = true;

  cardColors = {
    domain: ['#008F43', '#1938B3', '#FF9D00', '#0AFF7C', '#7990ec',  '#B02F1C']
  };

  graphColors = {
    domain: ['#0AFF7C', '#7990ec', '#1938B3']
  };

  constructor() { 
  }

  ngOnInit(): void {
    this.cardStats = [
      {
        "name": "Sent",
        "value": this.data?.totals?.sent + this.data?.totals?.drop || 0
      },
      {
        "name": "Delivered",
        "value": this.data?.totals?.inbox || 0
      },
      {
        "name": "Undeliverable (" + this.percentage(this.data?.totals?.bounce || 0 + this.data?.totals?.drop || 0, this.data?.totals?.sent, 2) + "%)",
        "value": this.data?.totals?.bounce || 0 + this.data?.totals?.drop || 0
      },
      {
        "name": "Opened (" + this.percentage(this.data?.totals?.open, this.data?.totals?.inbox, 2) + "%)",
        "value": this.data?.totals?.open || 0
      },
      {
        "name": "Clicked (" + this.percentage(this.data?.totals?.click, this.data?.totals?.inbox, 2) + "%)",
        "value": this.data?.totals?.click || 0
      },
      {
        "name": "Unsubscribed (" + this.percentage(this.data?.totals?.unsub || 0 + this.data?.totals?.report || 0, this.data?.totals?.inbox, 2) + "%)",
        "value": this.data?.totals?.unsub || 0 + this.data?.totals?.report || 0
      },
    ];


    let points = Object.keys(this.data.graphs);
    points.sort( (a: string, b: string) => parseInt(a) - parseInt(b) ); 

    let totalOpened = 0;
    let totalClicked = 0;
    let date = new Date( parseInt(points[0]) - 1000*60*60 ); 
    this.graphData[0].series.push( {"name": new Date(date.toString() + "Z"), "value": totalOpened} );
    this.graphData[1].series.push( {"name": new Date(date.toString() + "Z"), "value": totalClicked} );

    for (let point in points) {
      date = new Date( parseInt(points[point]) ); 
      totalOpened += this.data.graphs[points[point]]?.open || 0;
      totalClicked += this.data.graphs[points[point]]?.click || 0;

      this.graphData[0].series.push( {"name": new Date(date.toString() + "Z"), "value": totalOpened} );
      this.graphData[1].series.push( {"name": new Date(date.toString() + "Z"), "value": totalClicked} );
    };
  }
}
