import { firestore } from "firebase";
import { StorageService } from "src/app/services/firebase/storage.service";

export class Report {
    snapshot!: firestore.DocumentSnapshot;

    filename!: string;
    location!: string;
    source!: string;
    status!: string;
    created!: number;
    size: number = 0;

    constructor(init: Partial<Report> | Report | undefined, snapshot?: firestore.DocumentSnapshot) {
        Object.assign(this, init);

        if (snapshot) this.snapshot = snapshot;
    }

    download(storage: StorageService): void {
        storage.downloadFile(this.location).subscribe(res => {
            const a = document.createElement('a')
            a.href = res;
            a.download = this.filename + '.csv';
            a.click();
            a.remove();
        });
    }

    getSize(format: string = 'KB'): number {
        switch( format ) {
            case 'KB':
                return Math.round((this.size * 100)/1024)/100;
            default:
                return 0;
        }
    }
}
