import { firestore } from "firebase";

export class Event {
  snapshot?: firestore.DocumentSnapshot;

  Id: string | undefined;
  ip: string | undefined;
  time: string | undefined;
  type: string | undefined;

  /* Detail */
  url: string | undefined;
  useragent: string | undefined;
  bounceReason: string | undefined;

  constructor(init: Partial<Event> | Event | undefined, snapshot?: firestore.DocumentSnapshot) {
    Object.assign(this, init);

    if (snapshot) {
        this.snapshot = snapshot
        if (!init?.Id) this.Id = this.snapshot.id;
    }
  }

  getRecipientId(): string {
    return this.snapshot?.ref?.id || "";
  }
}