import { firestore } from "firebase";
import { Group } from "./group";

export class User {
	snapshot?: firestore.DocumentSnapshot;

	uid!: string;

	provider!: string;
	domain!: string;
	
	email!: string;
	displayName!: string;
	givenname!: string;
	surname!: string;

	tenants!: firestore.DocumentReference[];

	constructor(init: Partial<User> | User | undefined, snapshot?: firestore.DocumentSnapshot) {
		Object.assign(this, init);

		if (snapshot) this.snapshot = snapshot;
	}
}