import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/models/firebase/group';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {
  _groups!: Subscription;
  groups: { [uid: string]: Group} = {};

  constructor(data: DataService) { 
    data.clearLocalNav();
    data.addLocalNav("Reports", '/dashboard/reports');

    this._groups = data.getGroups().subscribe((groups: Group[]) => {
      groups.forEach((group: Group) => {
          this.groups[group.uid] = group;
      });
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._groups.unsubscribe();
  }

}
