import { firestore } from "firebase";

export class Campaign {
  snapshot?: firestore.DocumentSnapshot;

  Name: string | undefined;
  Type: string | undefined;

  LatestBatch: string | undefined;
  TotalBatches: number | undefined;


  totals : {  
    sent: number | undefined,
    deffer: number | undefined,
    drop: number | undefined,
    inbox: number | undefined,

    opened: number | undefined,
    clicked: number | undefined,
    
    unsubscribe: number | undefined,
    reported: number | undefined
  } | undefined;

  graphs: any;


  constructor(init: Partial<Campaign> | Campaign | undefined, snapshot?: firestore.DocumentSnapshot) {
    Object.assign(this, init);
    this.snapshot = snapshot;
  }

  getAccountID(): string {
    return this.snapshot?.ref?.parent?.parent?.id || "";
  }

  getCampaignID(): string {
    return this.snapshot?.id || "";
  }
}