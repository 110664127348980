<ng-template [ngIf]="!loading && campaigns?.length != 0">
    <div class="mat-elevation-z4">
        <table mat-table [dataSource]="campaigns" class="mat-elevation-z0" style="width: 100%;">
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let campaign">{{campaign?.Name || ""}}</td>
            </ng-container>
            <ng-container matColumnDef="Sent">
                <th mat-header-cell *matHeaderCellDef>Total Sent</th>
                <td mat-cell *matCellDef="let campaign">{{campaign?.totals?.sent || 0}}</td>
            </ng-container>
            <!-- Not Showing this for now as there is no way to filter out test sends on sendgrid.                
            <ng-container matColumnDef="Batches">
                <th mat-header-cell *matHeaderCellDef>Total Batches</th>
                <td mat-cell *matCellDef="let campaign">{{campaign?.TotalBatches || 0}}</td>
            </ng-container>
            -->
            <ng-container matColumnDef="LatestSend">
                <th mat-header-cell *matHeaderCellDef>Latest Batch</th>
                <td mat-cell *matCellDef="let campaign">{{(campaign?.LatestBatch | luxon) || ""}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
        </table>
    </div>
</ng-template>
<ng-template [ngIf]="!loading && !(campaigns?.length != 0)">
    <div style="display: flex; align-content: center; justify-content: center; width: 100%; height: 100%; margin: 1em;">
        <i>No campaigns to display yet.</i>
    </div>
</ng-template>
<ng-template [ngIf]="loading">
    <div style="display: flex; align-content: center; justify-content: center; width: 100%; height: 100%; margin: 1em;">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>