import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Batch } from 'src/app/models/reporting/batch';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})
export class BatchesComponent implements OnInit {
  loading: boolean = true;

  // Campaign Data
  _batches!: Subscription;
  batches!: Batch[];

  // Table
  batchesTable = new MatTableDataSource<Batch>(this.batches);
  displayedColumns: string[] = ['Type', 'Name', 'Sent On', 'Total Sent'];

  constructor(private data: DataService, private router: Router) { 
  }

  ngOnInit(): void {
    this._batches = this.data.getBatches().subscribe(
      res => { this.batches = res.filter(batch => batch?.Name != null && batch?.totals?.sent != null); },
      e => {},
      () => { this.loading = false; }
    );
  }

  ngOnDestroy(): void {
    this._batches?.unsubscribe();
  }

  select(batch: Batch): void {
    this.data.setLocalBatchName( batch.Name || "" );
    this.data.setLocalBatch( batch.getBatchID() );
    this.router.navigate( ['/dashboard', 'batch'] );
  }
}
