<div class="content">
    <h1>Batches</h1>
    <ng-template [ngIf]="!loading">
        <div class="table-responsive mat-elevation-z1">
            <table mat-table [dataSource]="batches" style="width: 100%;">
                <ng-container matColumnDef="Type">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let batch">
                        <div class="type">
                            <mat-icon>{{batch?.Type || ""}}</mat-icon>
                            <div>{{batch?.Type || ""}}</div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let batch">{{batch?.Name || ""}}</td>
                </ng-container>

                <ng-container matColumnDef="Total Sent">
                    <th mat-header-cell *matHeaderCellDef>Sent</th>
                    <td mat-cell *matCellDef="let batch">{{batch?.totals?.sent || "-"}}</td>
                </ng-container> 
 
                <ng-container matColumnDef="Sent On">
                    <th mat-header-cell *matHeaderCellDef>Scheduled</th>
                    <td mat-cell *matCellDef="let batch">{{(batch?.Scheduled || "?") | luxon}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
            </table>
        </div>
    </ng-template>
    <ng-template [ngIf]="loading">
        <div style="display: flex; align-content: center; justify-content: center; width: 100%; height: 100%;">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</div>