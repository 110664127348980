import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Campaign } from 'src/app/models/reporting/campaign';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit, OnDestroy {
  loading: boolean = true;

  _campaign!: Subscription;
  campaign!: Campaign;

  constructor(private data: DataService) { }

  ngOnInit(): void {
    this._campaign = this.data.getCampaign().subscribe(
      res => { 
        this.campaign = res;
        this.data.addLocalNav(this.campaign.Name || "Campaign", '/dashboard/campaign');
      }, 
      e =>   { console.error(e); }, 
      () =>  { this.loading = false; }
    );
  }

  ngOnDestroy(): void {
    this._campaign?.unsubscribe();
  }
}
