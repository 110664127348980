import { Component, Input, OnInit, Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { Recipient } from 'src/app/models/reporting/recipient';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/services/firebase/data.service';
import { MatDialog } from '@angular/material/dialog';
import { StoryComponent } from '../story/story.component';
import { MatRadioChange } from '@angular/material/radio';
import { Report } from 'src/app/models/firebase/report';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent implements OnInit {
  loading: boolean = true;

  // Filter
  selectedFilter: number = 0;
  filters: string[] = ['None', 'Delivered', 'Not Delivered', 'Opened', 'Not Opened', 'Clicked', 'Not Clicked', 'Unsubscribed'];

  // Campaign Data
  _recipients!: Subscription;
  recipients: Recipient[] = [];
  _recipientsLength!: Subscription;

  // Table
  recipientsTable = new MatTableDataSource<Recipient>(this.recipients);
  displayedColumns: string[] = ['Address', 'Sent', 'Dropped', 'Delivered', 'Bounced', 'Opened', 'Clicked', 'Unsubscribed', 'Reported'];

  constructor(private data: DataService, private router: Router, public dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getRecipients();
  }

  ngOnDestroy(): void {
    this._recipientsLength?.unsubscribe();
    this._recipients?.unsubscribe();
  }

  changeFilter(e?: MatRadioChange) {
    console.log('Unsubscribing from current Recipient Stream');
    this.loading = true;
    this.recipients = [];
    this._recipients?.unsubscribe();
    this.getRecipients()
  }

  getRecipient(recipient: Recipient): void {
    this.data.setLocalRecipient( recipient.getRecipientId() );

    const dialogRef = this.dialog.open( StoryComponent, {width: '33%', height: 'auto', data: {recipient: recipient}} );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  getRecipients() {
    this._recipients = this.data.getRecipients( this.recipients?.slice(-1)[0]?.Id || "0", this.filters[this.selectedFilter]  ).subscribe(
      res => { this.recipients = this.recipients.concat(res); },
      err => { this.recipients = []; console.error(err); },
      () =>  { this.loading = false; }
    );
  }

  download() {
    let filename = ((this.data.getLocalCampaignName() || "").split('.')[0] + '_' + (this.data.getLocalBatchName() || "").split('.')[0] + '.csv').replace(/[/\\?%*:|"<> ]/g, '_');
    let location = 'reports/' + this.data.getLocalGroup() + '/' + filename;
    let source = '/Accounts/' + this.data.getLocalAccount() + '/Campaigns/' + this.data.getLocalCampaign() + '/Batches/' + this.data.getLocalBatch() + '/Recipients';

    this.data.createReport(new Report({
      filename: filename,
      location: location,
      source: source,
      status: "Pending",
      created: Date.now(),
      size: 0
    })).then(res => {
      console.log("Successefully requested Report...");
      this.snackBar.open('Your report is being generated and will be available in "Downloads" soon, depending on the size of the report this may take a while... ', 'Close', {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }).catch(err => {
      console.error(err);
    });
  }

  onScroll() {
    console.log('Scroll to Load Event Triggered...');
    this.getRecipients();
  }
}
