import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* Account Access */
import { AccountComponent } from './views/account/account.component';
import { SignInComponent } from './views/account/sign-in/sign-in.component';

/* Dashboard */
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ReportsComponent } from './views/dashboard/reports/reports.component';
import { CampaignComponent } from './views/dashboard/reports/campaign/campaign.component';
import { BatchComponent } from './views/dashboard/reports/batch/batch.component';
import { AuthGuard } from './guards/auth.guard';

/* Errors */
import { Http404Component } from './views/errors/http404/http404.component';
import { SettingsComponent } from './views/dashboard/settings/settings.component';
import { DownloadsComponent } from './views/dashboard/downloads/downloads.component';
import { FeedbackComponent } from './views/dashboard/feedback/feedback.component';

const routes: Routes = [
  /* Account Access */
  { path: '', component: AccountComponent, children: [
    { path: 'sign-in', pathMatch: 'full', component: SignInComponent},
  ] },

  /* Dashboard */
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], children: [
    { path: '', pathMatch: 'full', redirectTo: '/dashboard/reports'}, // Redirect to overview by default.

    /* Report */
    { path: 'reports', pathMatch: 'full', component: ReportsComponent},
    { path: 'campaign', pathMatch: 'full', component: CampaignComponent},
    { path: 'batch', pathMatch: 'full', component: BatchComponent},
    
    /* Downloads */
    { path: 'downloads', pathMatch: 'full', component: DownloadsComponent},

    /* Settings */
    { path: 'feedback', pathMatch: 'full', component: FeedbackComponent },
    { path: 'settings', pathMatch: 'full', component: SettingsComponent },
  ] },

  /* 404 (Unknown) */
  { path: '**', component: Http404Component},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }


