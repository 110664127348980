<mat-drawer-container class="container" (window:resize)="onResize($event)"  [hasBackdrop]="false">
    <mat-drawer [mode]="mode" [opened]="opened">
        <mat-card class="draw">
            <div class="menu">
                <button mat-button disabled>
                    <div class="wrapper">
                        <img src="assets/images/WesternLogoSimple.svg"/>
                        <div>
                            <span class="main">{{_auth.User?.givenname}}</span>
                            <span class="sub">{{_auth.User?.domain}}</span>
                        </div>
                    </div>
                </button>
                <button mat-button routerLink="/dashboard/reports" [routerLinkActive]="['active']">
                    <div class="wrapper blue">
                        <mat-icon>bubble_chart</mat-icon>
                        <span>Reports</span>
                    </div>
                </button>
                <button mat-button routerLink="/dashboard/downloads" [routerLinkActive]="['active']">
                    <div class="wrapper green">
                        <mat-icon>download</mat-icon>
                        <span>Downloads</span>
                    </div>
                </button>
                <button mat-button routerLink="/dashboard/feedback" [routerLinkActive]="['active']">
                    <div class="wrapper orange">
                        <mat-icon>feedback</mat-icon>
                        <span>Feedback</span>
                    </div>
                </button>
                <button mat-button routerLink="/dashboard/settings" [routerLinkActive]="['active']">
                    <div class="wrapper orange">
                        <mat-icon>settings</mat-icon>
                        <span>Settings</span>
                    </div>
                </button>
                <button mat-button (click)="signOut()">
                    <div class="wrapper red">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sign Out</span>
                    </div>
                </button>
                <div style="flex-grow: 1;"></div>
                <div style="margin-top: auto; font-size: 0.7em; line-height: 1.6em; color: #6b6b6b;">
                    <b>Western Hub</b> V{{version()}}<br/>
                    <a href="#" style="color: #6b6b6b;">Privacy Policy</a> | <a href="#" style="color: #6b6b6b;">Terms of Service</a><br/>
                    &copy; {{year}} Western Mailing Limited
                </div>
            </div>
        </mat-card>
    </mat-drawer>
    <mat-drawer-content>
        <mat-card class="navigator">
            <button mat-icon-button aria-label="Toggle Side Menu" (click)="toggleOpen()">
                <mat-icon>{{opened ? 'menu_open' : 'menu'}}</mat-icon>
            </button>
            <h2 *ngFor="let item of _data.getLocalNav(); let i = index"><a (click)="_data.gotoLocalNav(i)">{{item.title}}</a></h2>
        </mat-card>
        
        <router-outlet></router-outlet>
        
    </mat-drawer-content>
</mat-drawer-container>
