import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { Group } from 'src/app/models/firebase/group';
import { Campaign } from 'src/app/models/reporting/campaign';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit, OnDestroy {
  @Input() group!: Group;
  loading: boolean = true;

  // Campaign Data
  _campaigns!: Subscription;
  campaigns!: Campaign[];

  // Table
  campaignsTable = new MatTableDataSource<Campaign>(this.campaigns);
  displayedColumns: string[] = ['Name', 'Sent', /*'Batches',*/ 'LatestSend'];

  constructor(private data: DataService, private router: Router) { }

  ngOnInit(): void {
    this._campaigns = this.data.getCampaignsByGroup(this.group).subscribe( res => { 
      this.campaigns = res
                          .filter( (x) => (x?.TotalBatches || 0) > 0 )
                          .sort( (a, b) => {
                            return DateTime.fromISO(b.LatestBatch || "1900-00-00", {zone: "utc"}).toMillis() - DateTime.fromISO(a.LatestBatch || "1900-00-00", {zone: "utc"}).toMillis()
                          });

      this.loading = false;
    } );
  }

  ngOnDestroy(): void {
    this._campaigns.unsubscribe();
  }

  select(campaign: Campaign): void {
    console.log( campaign.getAccountID() );
    this.data.setLocalCampaignName( campaign.Name || "" );
    this.data.setLocalGroup( this.group.getGroupID() )
    this.data.setLocalAccount( campaign.getAccountID() );
    this.data.setLocalCampaign( campaign.getCampaignID() );
    this.router.navigate( ['/dashboard', 'campaign'] );
  }
}
