<div style="display: flex; flex-direction: column; width: 100%; height: 100%;">
    <div class="container">
        <div class="cards">
            <ngx-charts-number-card 
                [results]="cardStats"
                [textColor]="'#fafafa'"
                [cardColor]="'#2b2b2b'"
                [innerPadding]="15"
                [scheme]="cardColors"
            >            
            </ngx-charts-number-card>
        </div>
        <div class="space">
            &nbsp;
        </div>
        <div class="chart">
            <h1>Total Opens and Clicks (by Hour)</h1>
            <div class="body">
                <ngx-charts-line-chart
                    [curve]="curve"
                    [scheme]="graphColors"
                    [legend]="legend"
                    [xAxis]="xAxis"
                    [yAxis]="yAxis"
                    [xAxisLabel]="xAxisLabel"
                    [yAxisLabel]="yAxisLabel"
                    [results]="graphData"
                    [roundDomains]="true"
                    [yScaleMin]="0"

                    [timeline]="timeline"
                ></ngx-charts-line-chart>
            </div>
        </div>
    </div>
</div>