import { firestore } from "firebase";

export class Batch {
  snapshot?: firestore.DocumentSnapshot;

  Id: string | undefined;
  Name: string | undefined;
  Type: string | undefined;
  Scheduled: Date | undefined;

  totals : {  
    sent: number | undefined,
    deffer: number | undefined,
    drop: number | undefined,
    inbox: number | undefined,

    opened: number | undefined,
    clicked: number | undefined,
    
    unsubscribe: number | undefined,
    reported: number | undefined
  } | undefined;

  graphs: any;


  constructor(init: Partial<Batch> | Batch | undefined, snapshot?: firestore.DocumentSnapshot) {
    Object.assign(this, init);

    if (snapshot) {
        this.snapshot = snapshot
        if (!init?.Id) this.Id = this.snapshot.id;
    }
  }

  getBatchID(): string {
    return this.snapshot?.ref?.id || "";
  }
}