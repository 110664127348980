import { firestore } from "firebase";
import { Group } from './group';

export class Invite {
    snapshot!: firestore.DocumentSnapshot;

	created!: Date;
	uid!: string;
	name!: string;
	group!: firestore.DocumentReference;

	constructor(init: Partial<Invite> | Invite | undefined, snapshot?: firestore.DocumentSnapshot) {
		Object.assign(this, init);
		if (snapshot) this.snapshot = snapshot;
	}
}