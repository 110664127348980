import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
export const firebaseConfig = environment.firebaseConfig;

/* Services */
import { AuthService } from './services/firebase/auth.service';

/* Material */
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatOptionModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/* Chart */
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InteractionsComponent } from './views/stats/interactions/interactions.component';
import { MzdTimelineModule } from 'ngx-mzd-timeline';

/* Components */
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { SignInComponent } from './views/account/sign-in/sign-in.component';
import { AccountComponent } from './views/account/account.component';
import { Http404Component } from './views/errors/http404/http404.component';

import { ReportsComponent } from './views/dashboard/reports/reports.component';
import { CampaignsComponent } from './views/dashboard/reports/campaigns/campaigns.component';
import { CampaignComponent } from './views/dashboard/reports/campaign/campaign.component';
import { BatchesComponent } from './views/dashboard/reports/batches/batches.component';
import { BatchComponent } from './views/dashboard/reports/batch/batch.component';
import { RecipientsComponent } from './views/dashboard/reports/recipients/recipients.component';
import { StoryComponent } from './views/dashboard/reports/story/story.component';

import { SettingsComponent } from './views/dashboard/settings/settings.component';
import { GroupComponent } from './views/dashboard/settings/group/group.component';
import { ConstructionComponent } from './views/errors/construction/construction.component';
import { GroupUserComponent } from './views/dashboard/settings/group/group-user/group-user.component';
import { LinksComponent } from './views/stats/links/links.component';
import { DownloadsComponent } from './views/dashboard/downloads/downloads.component';
import { DownloadListComponent } from './views/dashboard/downloads/download-list/download-list.component';
import { LuxonPipe } from './shared/pipes/luxon.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FeedbackComponent } from './views/dashboard/feedback/feedback.component';


@NgModule({
  declarations: [
    AppComponent,

    AccountComponent,
    SignInComponent,

    DashboardComponent,
    Http404Component,

    CampaignsComponent,
    CampaignComponent,
    BatchesComponent,
    BatchComponent,
    RecipientsComponent,
    StoryComponent,
    InteractionsComponent,
    GroupComponent,
    SettingsComponent,
    ConstructionComponent,
    GroupUserComponent,
    LinksComponent,
    DownloadsComponent,
    DownloadListComponent,
    ReportsComponent,
    LuxonPipe,
    FeedbackComponent,
  ],
  imports: [
    /* Priority Modules */
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    /* Angular Interactions */
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireStorageModule,

    /* Required Low Priority Modules */
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,

    /* Chart */
    NgxChartsModule,
    MzdTimelineModule,

    /* Material */
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatListModule,
    MatOptionModule,
    MatTabsModule,
    MatButtonToggleModule, 
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatGridListModule,
    MatSnackBarModule,
    FontAwesomeModule,
  ],
  providers: [
    AuthService,
    { provide: BUCKET, useValue: environment.firebaseConfig.storageBucket},
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
