import { firestore } from "firebase";

export class Recipient {
  snapshot?: firestore.DocumentSnapshot;

  Id: string | undefined;
  Address: string | undefined;
  Data: any;
  
  // Status
  delivered: boolean | undefined;
  opened: boolean | undefined;
  clicked: boolean | undefined;
  bounced: boolean | undefined;
  unsubscribed: boolean | undefined;
  reported: boolean | undefined;

  constructor(init: Partial<Recipient> | Recipient | undefined, snapshot?: firestore.DocumentSnapshot) {
    Object.assign(this, init);

    if (snapshot) {
        this.snapshot = snapshot
        if (!init?.Id) this.Id = this.snapshot.id;
    }
  }

  getRecipientId(): string {
    return this.snapshot?.ref?.id || "";
  }
}