<div class="content">
    <div class="header">
        <h1>Recipients <span *ngIf="selectedFilter > 0">({{filters[selectedFilter]}})</span></h1>
        <div class="buttons">
            <!-- Search --/> 
            <button mat-mini-fab aria-label="Search" color="primary"> 
                <mat-icon>search</mat-icon>
            </button> 
            <!-- Filter -->
            <button mat-mini-fab aria-label="Filter" color="primary" [matMenuTriggerFor]="menu" > 
                <mat-icon>filter_alt</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div class="filter-menu">
                    <mat-radio-group (change)="changeFilter($event)" [(ngModel)]="selectedFilter">
                        <mat-radio-button *ngFor="let filter of filters; let i = index" [value]="i">{{filter}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-menu>
            <!-- Download -->
            <button mat-mini-fab aria-label="Download" color="primary" (click)="download()"> 
                <mat-icon>download</mat-icon>
            </button>
            <!---->
        </div>
    </div>
    <ng-template [ngIf]="!loading">
        <div class="table-responsive mat-elevation-z1" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
            <table mat-table [dataSource]="recipients" style="width:100%;">
                <ng-container matColumnDef="Address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let recipient">{{recipient?.Address || ""}}</td>
                </ng-container>
                <ng-container matColumnDef="Sent">
                    <th mat-header-cell *matHeaderCellDef>Sent</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.sent">{{recipient?.sent ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Dropped">
                    <th mat-header-cell *matHeaderCellDef>Dropped</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.drop">{{recipient?.drop ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Delivered">
                    <th mat-header-cell *matHeaderCellDef>Delivered</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.inbox">{{recipient?.inbox ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Bounced">
                    <th mat-header-cell *matHeaderCellDef>Bounced</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.bounce">{{recipient?.bounce ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Opened">
                    <th mat-header-cell *matHeaderCellDef>Opened</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.open">{{recipient?.open ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Clicked">
                    <th mat-header-cell *matHeaderCellDef>Clicked</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.click">{{recipient?.click ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Unsubscribed">
                    <th mat-header-cell *matHeaderCellDef>Unsubscribed</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.unsub">{{recipient?.unsub ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="Reported">
                    <th mat-header-cell *matHeaderCellDef>Reported</th>
                    <td mat-cell *matCellDef="let recipient"><mat-icon [class.grey]="!recipient?.report">{{recipient?.report ? "done" : "close"}}</mat-icon></td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getRecipient(row)"></tr>
            </table>
            <div class="no-results" *ngIf="recipients.length === 0">
                No recipients found...<br/> 
                <a *ngIf="selectedFilter > 0" (click)="selectedFilter = 0; changeFilter();">Do you want to clear the filter?</a>
            </div>
        </div>
    </ng-template>
    <ng-template [ngIf]="loading">
        <mat-spinner></mat-spinner>
    </ng-template>
</div>