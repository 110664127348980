<div style="margin: 2em 1em 1em 1em;">
    <p style="text-align: center; font-weight: 300;">Invite users to, and remove users from groups that you administer. You can also specify who else has administration rights.</p>
    <mat-accordion multi>
        <mat-expansion-panel *ngFor="let group of groups | keyvalue; first as isFirst" [expanded]="isFirst" [disabled]="group?.value?.users?.length == 0">
            <!-- HEADER -->
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <b>{{group.value.name}}</b>
                </mat-panel-title>
                <mat-panel-description>
                    {{group.value.users.length || 0}} Users
                </mat-panel-description>
            </mat-expansion-panel-header>
            <!-- BODY -->
            <ng-template matExpansionPanelContent>
                <div class="users">
                    <div class="header">
                        <div>Display Name</div>
                        <div>Email</div>
                        <div>Group Admin</div>
                    </div>
                    <div *ngFor="let user of group.value.users" class="user">
                        <app-group-user [group]="group.value" [user]="user"></app-group-user>
                    </div>
                </div>
            </ng-template>
            <!-- END -->
        </mat-expansion-panel>
    </mat-accordion>
</div>