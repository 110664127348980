<mat-card class="content-card">
    <div class="content">
        <ng-template [ngIf]="!loading">
            <div class="top">
                <h1>{{campaign.Name}}</h1>
                <div style="width:100%; height: 350px;">
                    <app-interactions [data]="campaign" style="width:100%; height: 100%;"></app-interactions>
                </div>
            </div>
            <div class="bottom">
                <div class="left-side">
                    <app-batches></app-batches>
                </div>
                <div class="right-side">
                    <app-links></app-links>
                </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="loading">
            <div style="display: flex; align-content: center; justify-content: center; width: 100%; height: 100%;">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</mat-card>