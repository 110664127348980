<div class="page-container">
    <div class="brand">
        <a href="https://western.co.nz/" target="_blank">
            <img width="300px" src="/assets/images/WesternMailingLogo270px.png"/>
        </a>
    </div>
    <div class="info">
        <b>Western Hub</b> V{{version()}}<br/>
        <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a><br/>
        &copy; {{year}} Western Mailing Limited
    </div>
    <div class="content-container">
        <div [ngClass]="{'loader': true, 'visible': _auth.transactionActive}">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>