import { firestore } from "firebase";

export class Account {
  snapshot?: firestore.DocumentSnapshot;

  Id: string | undefined;
  Name: string | undefined;

  constructor(init: Partial<Account> | Account | undefined, snapshot?: firestore.DocumentSnapshot) {
    Object.assign(this, init);

    if (snapshot) {
      this.snapshot = snapshot
      if (!init?.Id) this.Id = this.snapshot.id;
    }
  }
}