import { Injectable } from '@angular/core';
import { AngularFirestore, QueryDocumentSnapshot, DocumentReference, QueryFn, DocumentData } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { from, Observable, of } from 'rxjs';
import { distinct, map, mergeMap, reduce, scan, take, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { Group } from 'src/app/models/firebase/group';
import { User } from 'src/app/models/firebase/user';

import { Account } from 'src/app/models/reporting/account';
import { Batch } from 'src/app/models/reporting/batch';
import { Campaign } from 'src/app/models/reporting/campaign';
import { Recipient } from 'src/app/models/reporting/recipient';
import { Event } from 'src/app/models/reporting/event';
import { Invite } from 'src/app/models/firebase/invite';
import { Link } from 'src/app/models/reporting/link';
import { Report } from 'src/app/models/firebase/report';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private firestore: AngularFirestore, private authService: AuthService, private router: Router) { }

  /*
    Local Storage
  */
  public addLocalNav( title: string, url: string) {
    let navigator: { title: string, url: string }[] = JSON.parse( localStorage.getItem('navigator') || "[]" );
    if (navigator.filter(i => i.title == title).length > 0) return;
    navigator.push( { title: title, url: url } );
    localStorage.setItem('navigator', JSON.stringify(navigator));
  }

  public getLocalNav(): { title: string, url: string }[] {
    return JSON.parse( localStorage.getItem('navigator') || "[]" );
  }

  public clearLocalNav(index: number = 0): void {
    if (!index) {
      localStorage.removeItem('navigator');
    } else {
      let navigator: { title: string, url: string }[] = JSON.parse( localStorage.getItem('navigator') || "[]" );
      navigator = navigator.slice(0, index + 1);
      localStorage.setItem('navigator', JSON.stringify(navigator));
    }
  }

  public gotoLocalNav(index: number) {
    let goto = this.getLocalNav()[index];
    this.clearLocalNav(index);
    this.router.navigate([goto.url]);
  }

  public setLocalGroup(groupId: string) {
    localStorage.setItem('GroupID', groupId);
  }

  public getLocalGroup(): string {
    return localStorage.getItem('GroupID') || "";
  }

  public setLocalAccount(accountId: string) {
    localStorage.setItem('AccountID', accountId);
  }

  public getLocalAccount(): string {
    return localStorage.getItem('AccountID') || "";
  }

  public setLocalCampaign(campaignId: string) {
    localStorage.setItem('CampaignID', campaignId);
  }

  public getLocalCampaign(): string {
    return localStorage.getItem('CampaignID') || "";
  }

  public setLocalBatch(batchId: string) {
    localStorage.setItem('BatchID', batchId);
  }

  public getLocalBatch(): string {
    return localStorage.getItem('BatchID') || "";
  }

  public setLocalRecipient(recipientId: string) {
    localStorage.setItem('RecipientID', recipientId);
  }

  public getLocalRecipient(): string {
    return localStorage.getItem('RecipientID') || "";
  }

  public setLocalBatchName(name: string): void {
    localStorage.setItem('BatchName', name);
  }

  public getLocalBatchName(): string {
    return localStorage.getItem('BatchName') || "";
  }

  public setLocalCampaignName(name: string): void {
    localStorage.setItem('CampaignName', name);
  }

  public getLocalCampaignName(): string {
    return localStorage.getItem('CampaignName') || "";
  }


  /* 
    USER
  */
  public getUser(ref?: DocumentReference): Observable<User> {
    let $user: Observable<firestore.DocumentSnapshot<User>>;

    if (!ref) {
      $user = this.firestore.doc<User>('Users/' + this.authService.User?.uid).get()
    } else {
      $user = this.firestore.doc<User>(ref).get();
    }

    return $user.pipe(
      map( (res: firestore.DocumentSnapshot<User>) => new User(res.data(), res) ),
      take(1),
    );
  }

  public submitFeedback(feedback: any): Promise<DocumentReference<any>> {
    let feedbackDetails = {
      timestamp: new Date(Date.now()),
      userID: this.authService.User?.uid,
      email: this.authService.User?.email,
      type: feedback.type,
      message: feedback.detail,
    }

    return this.firestore.collection('Feedback').add(feedbackDetails);
  }

  /* 
    GROUPS
  */
  public getGroups(): Observable<Group[]> {
    return this.getUser().pipe(
      mergeMap( (user: User) => user.tenants ),
      distinct( (groupRef: firestore.DocumentReference) => groupRef.path ),
      mergeMap( (groupRef: firestore.DocumentReference) => this.firestore.doc<Group>(groupRef.path).get() ),
      map( (res: firestore.DocumentSnapshot<Group>) => new Group(res.data(), res) ),
      reduce( (groups: Group[], group: Group) => [...groups, group ], [] ),
    );
  }

  public getGroupsLive(): Observable<Group[]> {
    return this.getUser().pipe(
      switchMap( (user: User) => from(user.tenants) ),
      mergeMap( (ref) => this.firestore.doc<Group>(ref).snapshotChanges() ),
      map( (actions) => new Group(actions.payload.data(), actions.payload) ),
      scan( (groups: Group[], group: Group) => [...groups, group], [] )
    );
  }

  /*
    REPORTS
  */
  public getReports(group: Group): Observable<Report[]> {
    return this.firestore.collection<Report>( group.snapshot.ref.collection('Reports') ).get().pipe(
      switchMap(col => col.docs),
      map(doc => new Report(doc.data(), doc) ),
      reduce( (reports: Report[], report: Report ) => [...reports, report], [])
    )
  }

  public createReport(report: Report): Promise<DocumentReference<DocumentData>> {
    return this.firestore.collection('Groups').doc(this.getLocalGroup()).collection('Reports').add({
      filename: report.filename,
      created: report.created,
      size: report.size,
      source: report.source,
      location: report.location,
      status: report.status
    });
  }

  /* 
    ACCOUNTS
  */
  public getAccounts(): Observable<Account[]> {
    return this.getGroups().pipe(
      mergeMap( (groups: Group[]) => from(groups) ),
      scan( (accountRefs: firestore.DocumentReference[], group: Group) => [...accountRefs, ...group.accounts], [] ),
      mergeMap( (accountRefs: firestore.DocumentReference[]) => accountRefs ),
      mergeMap( (accountRef: firestore.DocumentReference) => this.firestore.doc<Account>(accountRef.path).get() ),
      reduce( (accounts: Account[], res: firestore.DocumentSnapshot<Account>) => [...accounts, new Account(res.data(), res)], [] ),
    );
  }

  /*
    CAMPAIGNS
  */
  public getCampaigns(): Observable<Campaign[]> {
    return this.getGroups().pipe(
      mergeMap( (groups: Group[]) => from(groups) ), // Branch for Each Group.
      reduce( (accountRefs: firestore.DocumentReference[], group: Group) => [...accountRefs, ...group.accounts], [] ), // Reduce Branches to Array of Account References (Taken from Groups).
      mergeMap( (accountRefs: firestore.DocumentReference[]) => accountRefs ), // Get Refs per Account.
      mergeMap( (accountRef: firestore.DocumentReference) => this.firestore.doc(accountRef.path).collection<Campaign>('Campaigns').get() ), // Load Campaigns for Each Account.
      mergeMap( (res: firestore.QuerySnapshot<Campaign>) => res.docs), // Get Docs for Each Query Snapshot.
      reduce( (campaigns: QueryDocumentSnapshot<Campaign>[], campaign: QueryDocumentSnapshot<Campaign>) => campaigns.concat(campaign), [] ), // Reduce Branches to Array of Campaign Query Document Snapshots.
      mergeMap( (campaigns: QueryDocumentSnapshot<Campaign>[]) => from(campaigns) ), // Branch for Each Campaign Query Document.
      map( (campaign: QueryDocumentSnapshot<Campaign>) => new Campaign(campaign.data(), campaign) ), // Map Campaign Query Document to new Campaign Object.
      reduce( (campaigns: Campaign[], campaign: Campaign) => [...campaigns, campaign], [] ), // Reduce Branches to Array of Campaigns.
    );
  }

  public getCampaignsByGroup(group: Group): Observable<Campaign[]> {
    return this.firestore.doc<Group>(group.snapshot.ref).get().pipe(
      map( (res: firestore.DocumentSnapshot<Group>) => new Group(res.data(), res) ), // Get Group Object.
      mergeMap( (group: Group) => group.accounts ), // Get Refs per Account.
      mergeMap( (accountRef: firestore.DocumentReference) => this.firestore.doc(accountRef.path).collection<Campaign>('Campaigns').get() ), // Load Campaigns for Each Account.
      mergeMap( (res: firestore.QuerySnapshot<Campaign>) => res.docs), // Get Docs for Each Query Snapshot.
      reduce( (campaigns: QueryDocumentSnapshot<Campaign>[], campaign: QueryDocumentSnapshot<Campaign>) => campaigns.concat(campaign), [] ), // Reduce Branches to Array of Campaign Query Document Snapshots.
      mergeMap( (campaigns: QueryDocumentSnapshot<Campaign>[]) => from(campaigns) ), // Branch for Each Campaign Query Document.
      map( (campaign: QueryDocumentSnapshot<Campaign>) => new Campaign(campaign.data(), campaign) ), // Map Campaign Query Document to new Campaign Object.
      reduce( (campaigns: Campaign[], campaign: Campaign) => [...campaigns, campaign], [] ), // Reduce Branches to Array of Campaigns.
    );
  }

  public getCampaign(accountId: string | null = null, campaignId: string | null = null): Observable<Campaign> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();

    return this.firestore.doc<Campaign>('/Accounts/' + accountId + '/Campaigns/' + campaignId).get().pipe(
      map((res: firestore.DocumentSnapshot<Campaign>) => new Campaign(res.data(), res))
    );
  }

  /* 
    BATCHES
  */
  public getBatches(accountId: string | null = null, campaignId: string | null = null): Observable<Batch[]> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();

    return this.firestore.doc('/Accounts/' + accountId + '/Campaigns/' + campaignId).collection<Batch>('Batches').get().pipe(
      mergeMap( (res: firestore.QuerySnapshot<Batch>) => res.docs ),
      reduce( (batches: QueryDocumentSnapshot<Batch>[], batch: QueryDocumentSnapshot<Batch>) => batches.concat(batch), [] ),
      mergeMap( (batches: QueryDocumentSnapshot<Batch>[]) => from(batches) ),
      map( (batch: QueryDocumentSnapshot<Batch>) => new Batch(batch.data(), batch) ),
      reduce( (batches: Batch[], batch: Batch) => [...batches, batch], [] ),
    );
  }

  public getBatch(accountId: string | null = null, campaignId: string | null = null, batchId: string | null = null): Observable<Batch> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();
    if (!batchId) batchId = this.getLocalBatch();

    return this.firestore.doc<Batch>('/Accounts/' + accountId + '/Campaigns/' + campaignId + '/Batches/' + batchId).get().pipe(
      map((res: firestore.DocumentSnapshot<Batch>) => new Batch(res.data(), res))
    );
  }

  /* 
    RECIPIENTS
  */
  public getRecipients(lastDocId: string, filter: string | null = null, accountId: string | null = null, campaignId: string | null = null, batchId: string | null = null): Observable<Recipient[]> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();
    if (!batchId) batchId = this.getLocalBatch();

    let queryFn: QueryFn<firestore.DocumentData>;
    switch(filter?.toLowerCase()) {
      case "delivered":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('inbox', '==', true).startAfter(lastDocId).limit(20); break;
      case "not delivered":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('inbox', '==', false).startAfter(lastDocId).limit(20); break;
      case "opened":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('open', '==', true).startAfter(lastDocId).limit(20); break;
      case "not opened":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('open', '==', false).startAfter(lastDocId).limit(20); break;
      case "clicked":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('click', '==', true).startAfter(lastDocId).limit(20); break;
      case "not clicked":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('click', '==', false).startAfter(lastDocId).limit(20); break;
      case "unsubscribed":
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).where('unsub', '==', true).startAfter(lastDocId).limit(20); break;
      default:
        queryFn = (ref) => ref.orderBy(firestore.FieldPath.documentId()).startAfter(lastDocId).limit(20);
    }

    return this.firestore.doc('/Accounts/' + accountId + '/Campaigns/' + campaignId + '/Batches/' + batchId)
      .collection<Recipient>('Recipients', queryFn).get().pipe(
        mergeMap( (res: firestore.QuerySnapshot<Recipient>) => res.docs ),
        reduce( (recipients: QueryDocumentSnapshot<Recipient>[], recipient: QueryDocumentSnapshot<Recipient>) => recipients.concat(recipient), [] ),
        mergeMap( (recipients: QueryDocumentSnapshot<Recipient>[]) => from(recipients) ),
        map( (recipient: QueryDocumentSnapshot<Recipient>) => new Recipient(recipient.data(), recipient) ),
        reduce( (recipients: Recipient[], recipient: Recipient) => [...recipients, recipient], [] ),
      );
  }

  public getRecipient(accountId: string | null = null, campaignId: string | null = null, batchId: string | null = null, recipientId: string | null = null): Observable<Recipient> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();
    if (!batchId) batchId = this.getLocalBatch();
    if (!recipientId) recipientId = this.getLocalRecipient();

    return this.firestore.doc<Recipient>('/Accounts/' + accountId + '/Campaigns/' + campaignId + '/Batches/' + batchId + '/Recipient/' + recipientId).get().pipe(
      map((res: firestore.DocumentSnapshot<Recipient>) => new Recipient(res.data(), res))
    );
  }

  /* 
    EVENTS
  */
  public getEvents(accountId: string | null = null, campaignId: string | null = null, batchId: string | null = null, recipientId: string | null = null): Observable<Event[]> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();
    if (!batchId) batchId = this.getLocalBatch();
    if (!recipientId) recipientId = this.getLocalRecipient();

    return this.firestore.doc('/Accounts/' + accountId + '/Campaigns/' + campaignId + '/Batches/' + batchId + '/Recipients/' + recipientId)
      .collection<Event>('Events', (ref) => ref.orderBy('time')).get().pipe(
        mergeMap( (res: firestore.QuerySnapshot<Event>) => res.docs ),
        reduce( (events: QueryDocumentSnapshot<Event>[], event: QueryDocumentSnapshot<Event>) => events.concat(event), [] ),
        mergeMap( (events: QueryDocumentSnapshot<Event>[]) => from(events) ),
        map( (event: QueryDocumentSnapshot<Event>) => new Event(event.data(), event) ),
        reduce( (events: Event[], event: Event) => [...events, event], [] ),
      );
  }

  /* 
    INVITE
  */
  async generateInvite(group: Group): Promise<string> {
    let url: string = "Could not generate an invite link.";

    await this.firestore.collection("/Invites/").add({
      created: new Date(Date.now()),
      uid: group.uid,
      name: group.name,
      group: group.snapshot?.ref,
    }).then(res => {
      url = window.location.origin + '/invite/' + res.id; 
    }).catch(res => {
      url = "Could not generate and invite link.";
    });

    return url;
  }

  getInvite(code: string): Observable<Invite> {
    return this.firestore.doc<Invite>("/Invites/" + code).get().pipe(
      map( (res) => new Invite(res.data(), res) )
    );
  }

  /* 
    LINKS
  */
  public getLinks(accountId: string | null = null, campaignId: string | null = null, batchId: string | null = null): Observable<Link[]> {
    if (!accountId) accountId = this.getLocalAccount();
    if (!campaignId) campaignId = this.getLocalCampaign();
    if (!batchId) batchId = this.getLocalBatch();

    // Focus Campaign or Batch.
    let target: string = '/Accounts/' + accountId + '/Campaigns/' + campaignId + (batchId != "" ? '/Batches/' + batchId : '') + '/Links/';

    return this.firestore.collection<Link>(target).get().pipe(
      mergeMap( (res: firestore.QuerySnapshot<Link>) => res.docs ),
      reduce( (links: QueryDocumentSnapshot<Link>[], link: QueryDocumentSnapshot<Link>) => links.concat(link), [] ),
      mergeMap( (links: QueryDocumentSnapshot<Link>[]) => from(links) ),
      map( (link: QueryDocumentSnapshot<Link>) => new Link(link.data(), link) ),
      reduce( (links: Link[], link: Link) => [...links, link], [] ),
    );
  }

}