<div>
    <form class="feedback-form" [formGroup]="feedbackForm">
        <div>
            <p>
                <b>Your feedback is important to us!</b> 
                We constantly seek feedback to improve and evolve our service, whilst identifying opportunities to assist clients in realising their business objectives. 
            </p>
        </div>
        <mat-form-field class="" appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type" required>
                <mat-option value="Bug">Bug Report</mat-option>
                <mat-option value="Feature Request">Feature Request</mat-option>
                <mat-option value="Suggestion">Suggestion</mat-option>
                <mat-option value="Other">Other</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="" appearance="fill">
            <mat-label>Details</mat-label>
            <textarea formControlName="detail" matInput required></textarea>
        </mat-form-field>
        <div><button type="reset" mat-flat-button color="primary" [disabled]="!feedbackForm.valid" (click)="submit()">Submit</button></div>
    </form>
</div>