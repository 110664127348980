<div class="signin-container">
    
    <!-- Sign In -->
    <div>
        <div class="error" *ngIf="_auth.signInError != null" (click)="_auth.signInError = null">
            <span>{{_auth.signInError}}</span>
            <button mat-icon-button aria-label="Close" (click)="_auth.signInError = null">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <button class="provider-signin" mat-raised-button color="white" (click)="MicrosoftSignIn()" [disabled]="_auth.transactionActive">
            <img width="20px" src="/assets/images/Microsoft.svg"> &nbsp;&nbsp;&nbsp;
            Sign in with Microsoft
        </button>

        <button class="provider-signin" mat-raised-button color="white" (click)="MicrosoftSignIn()" [disabled]="_auth.transactionActive || true">
            <img width="20px" src="/assets/images/Google.svg"> &nbsp;&nbsp;&nbsp;
            Sign in with Google
        </button>
    </div>

</div>
