// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAGB3WVr-YwWR0YNCARlJXe-2hvlMdtLfU",
    authDomain: "western-hub-live.firebaseapp.com",
    projectId: "western-hub-live",
    storageBucket: "western-hub-live.appspot.com",
    messagingSenderId: "175553521936",
    appId: "1:175553521936:web:b22b9f99f39d6eab6b1d95",
    measurementId: "G-P47ELXWNEZ"
  }
  // firebaseConfig: {
  //   apiKey: "AIzaSyAgkkY7xIVlifg2D2CS1rx9zjjdN5E4iDM",
  //   authDomain: "westernreportinghub.firebaseapp.com",
  //   projectId: "westernreportinghub",
  //   storageBucket: "westernreportinghub.appspot.com",
  //   messagingSenderId: "1022628746648",
  //   appId: "1:1022628746648:web:1471cb06ad7c6bd08687ed"
  // }
};
