
<mat-accordion class="content" multi>
    <mat-expansion-panel *ngFor="let group of groups | keyvalue; first as isFirst" [expanded]="isFirst">
        <!-- HEADER -->
        <mat-expansion-panel-header>
            <mat-panel-title>
                <b>{{group.value.name}}</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- BODY -->
        <mat-divider></mat-divider>
        <ng-template matExpansionPanelContent>
            <app-download-list [group]="group.value"></app-download-list>
        </ng-template>
        <!-- END -->
    </mat-expansion-panel>
</mat-accordion>
