import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackForm = new FormGroup({
    type: new FormControl('', Validators.required),
    detail: new FormControl('', Validators.required),
  });

  constructor(private data: DataService, private snackBar: MatSnackBar) {
    data.clearLocalNav();
    data.addLocalNav("Feedback", '/dashboard/feedback');
  }

  ngOnInit(): void {
  }

  submit() {
    this.data.submitFeedback(this.feedbackForm.value).then(res => {
      this.feedbackForm.reset();
      this.feedbackForm.markAsPristine();
      this.feedbackForm.markAsUntouched();
      this.snackBar.open('Your feedback has been recieved, thank you!', 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }).catch(err => {
      this.snackBar.open('There was an error submitting your feedback, please try again in a minute.', 'Close', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    });

  }

}
