import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Link } from 'src/app/models/reporting/link';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {
  loading: boolean = true;

  // Campaign Data
  _links!: Subscription;
  links!: Link[];

  // Table
  linksTable = new MatTableDataSource<any>(this.links);
  displayedColumns: string[] = ['URL', 'Clicks'];

  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    this._links = this.data.getLinks().subscribe(
      res => { this.links = res; }, 
      e =>   { console.error(e); }, 
      () =>  { this.loading = false; }
    );
  }

}
