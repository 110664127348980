<mat-list style="padding: 24px;">
    <h3 style="margin-bottom: 0;">Ready</h3>
    <mat-list-item *ngFor="let report of reportsReady">
        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
        <div mat-line><b>{{report.filename || ''}}</b>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.8em;">{{report.getSize() || '?'}} KB</span></div>
        <div mat-line>Created at {{report.created | date:'h:mma'}} on {{report.created | date:'d/M/yyyy'}}</div>
        <button mat-icon-button (click)="report.download(storage)">
            <mat-icon>download</mat-icon>
        </button>
    </mat-list-item>
    <div *ngIf="reportsReady?.length == 0">
        <div style="display: flex; width: 100%; height: 100%;">
            <i>No reports are available to download.</i>
        </div>
    </div>
    <h3 style="margin-top: 1em; margin-bottom: 0;">Pending</h3>
    <mat-list-item *ngFor="let report of reportsPending">
        <mat-icon mat-list-icon>insert_drive_file</mat-icon>
        <div mat-line><b>{{report.filename || ''}}</b>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-size: 0.8em;">{{report.getSize() || '-'}} KB</span></div>
        <div mat-line>Created at {{report.created | date:'h:mma'}} on {{report.created | date:'d/M/yyyy'}}</div>
        <button mat-icon-button [disabled]="true" (click)="report.download(storage)">
            <mat-icon>download</mat-icon>
        </button>
    </mat-list-item>
    <div *ngIf="reportsPending?.length == 0">
        <div style="display: flex; width: 100%; height: 100%;">
            <i>No reports are being generated at the moment.</i>
        </div>
    </div>
</mat-list>
