<mat-card class="content-card">
    <div class="container">
        <ng-template [ngIf]="!loading">
            <div class="container">
                <div class="top">
                    <div style="display: flex; flex-direction: row; margin-bottom: 1em; max-width: 100%; color: #2b2b2b;">
                        <div style="display: flex; justify-content: center; align-items: center;"> <mat-icon>{{batch.Type}}</mat-icon> </div>
                        <div style="display: flex; flex-direction: column; padding-left: 1em;">
                            <div style="font-size: 1.2em;">{{batch.Name}}</div>
                            <div style="font-size: 0.8em;">{{sent ? "Scheduled for " : "Sent on "}}{{(batch?.Scheduled || "") | luxon}}</div>
                        </div>
                    </div>
                    <div style="width:100%; height: 350px;">
                        <app-interactions [data]="batch" style="width:100%; height: 100%;"></app-interactions>
                    </div>
                </div>
                <div class="bottom">
                    <app-recipients class="left-side"></app-recipients>
                    <app-links class="right-side"></app-links>
                </div>
        </div>
        </ng-template>
        <ng-template [ngIf]="loading">
            <div style="display: flex; align-content: center; justify-content: center; width: 100%; height: 100%;">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</mat-card>