import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  constructor(private auth: AuthService, private router: Router) {}

  ngOnInit(): void { }

  _auth = this.auth;

  hidePassword = true;

  signinForm = new FormGroup({
    emailField: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    passwordField: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  PasswordReset() {
    this.router.navigate(['/forgot-password']);
  }

  MicrosoftSignIn() {
    this.auth.MicrosoftSignIn();
  }
}
