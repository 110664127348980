import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: AngularFireStorage, private db: AngularFirestore) { }

  downloadFile(location: string): Observable<any> {
    const ref = this.storage.ref(location);
    return ref.getDownloadURL();
  }

}
