import { Component, OnInit, Input } from '@angular/core';
import { Group } from 'src/app/models/firebase/group';
import { User } from 'src/app/models/firebase/user';
import { DocumentReference, DocumentData } from '@angular/fire/firestore';
import { DataService } from 'src/app/services/firebase/data.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-group-user',
  templateUrl: './group-user.component.html',
  styleUrls: ['./group-user.component.scss']
})
export class GroupUserComponent implements OnInit {
  @Input() group!: Group;
  @Input() user!: DocumentReference<DocumentData>;

  userData!: User;
  admin!: boolean;
  deleted: boolean = false;
  disabled: boolean = false;

  constructor(private data: DataService, private auth: AuthService) { }

  ngOnInit(): void { 
    this.data.getUser(this.user).subscribe((res:User) => {
      this.userData = res;
      this.disabled = res.uid === this.auth.User?.uid;
      this.admin = this.group.admins
        .map(ref=>ref.path)
        .includes( ('Users/' + res.uid) );
    });
  }

  toggleAdmin($event: any): void {
    this.admin=$event.checked;
    if ($event.checked) {
      this.group.addAdmin(this.userData);
    } else {
      this.group.dropAdmin(this.userData);
    }
  }

  deleteGroupUser(undo?: boolean): void {
    if (!undo) {
      this.deleted = true;
      this.group.dropUser(this.userData);
    } else {
      this.deleted = false;
      this.group.addUser(this.userData, this.admin);
    }
  }
}
