import { firestore } from "firebase";
import { User } from './user';

export class Group {
    snapshot!: firestore.DocumentSnapshot;

    uid!: string;
    name!: string;
    logo!: string;

    accounts!: firestore.DocumentReference[];
    admins!: firestore.DocumentReference[];
    users!: firestore.DocumentReference[];

    constructor(init: Partial<Group> | Group | undefined, snapshot?: firestore.DocumentSnapshot) {
        Object.assign(this, init);

        if (snapshot) this.snapshot = snapshot;
    }

    getGroupID(): string {
        return this.snapshot?.id || "";
    }

    addAdmin(user: User): void {
        console.log("Making Admin...");
        console.log(user);
        console.log(this);
        this.snapshot?.ref.update({
            admins: firestore.FieldValue.arrayUnion(user.snapshot!.ref)
        });        
    }

    dropAdmin(user: User): void {
        this.snapshot?.ref.update({
            admins: firestore.FieldValue.arrayRemove(user.snapshot!.ref)
        });
    }

    addUser(user: User, admin?: boolean): void {
        this.snapshot?.ref.update({
            users: firestore.FieldValue.arrayUnion(user.snapshot!.ref)
        });

        if (admin) this.addAdmin(user);
    }

    dropUser(user: User): void {
        this.snapshot?.ref.update({
            admins: firestore.FieldValue.arrayRemove(user.snapshot!.ref),
            users: firestore.FieldValue.arrayRemove(user.snapshot!.ref)
        });
    }
}
