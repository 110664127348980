<div class="content">
    <h1>Links</h1>
    <ng-template [ngIf]="!loading">
        <div class="table-responsive mat-elevation-z1">
            <table mat-table [dataSource]="links" style="width: 100%;">
                <ng-container matColumnDef="URL">
                    <th mat-header-cell *matHeaderCellDef>URL (Link)</th>
                    <td mat-cell *matCellDef="let link"><a [href]="link?.url" target="_blank">{{link?.url || ""}}</a></td>
                </ng-container>
                <ng-container matColumnDef="Clicks">
                    <th mat-header-cell *matHeaderCellDef>Clicks</th>
                    <td mat-cell *matCellDef="let link">{{link?.click || ""}}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </ng-template>
    <ng-template [ngIf]="loading">
        <div>
            Loading...
        </div>
    </ng-template>
</div>