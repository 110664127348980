import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/firebase/data.service';
import { Group } from 'src/app/models/firebase/group';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DocumentReference } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {
  _groups!: Subscription;
  groups: { [uid: string]: Group} = {};

  constructor(auth: AuthService, data: DataService, public dialog: MatDialog) { 
    this._groups = data.getGroups().subscribe((res: Group[]) => {
      let groups = res.filter(
        (group) => ( group.admins.findIndex((se: DocumentReference) => se.path == ('Users/' + auth.User?.uid)) != -1 )
      );
      
      groups.forEach((group: Group) => {
        if ( this.groups[group.uid] ) {
          this.groups[group.uid]
        } else {
          this.groups[group.uid] = group;
        }
      });
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._groups.unsubscribe();
  }
}
