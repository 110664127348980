import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { User } from 'src/app/models/firebase/user';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public projectId: string = environment.firebaseConfig.projectId;

  public authorised: boolean = false;
  public transactionActive: boolean = true;
  public signInError: string | null = null;
  public signUpError: string | null = null;

  private $User!: Subscription;

  set User(user: User | undefined) {
    if (user) { 
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user')
    }
  }

  get User(): ( User | undefined ) {
    const store = localStorage.getItem('user');
    if (!store) return undefined;
    const user = JSON.parse(store);
    return user;
  }

  constructor(
    private firestore: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private router: Router,
  ) {
    this.firebaseAuth.getRedirectResult()
      .then((res) => {
        if (res.credential && res.user) {
          switch (res.credential.providerId) {
            case "saml.azure":
              break;
            default:
              throw new Error("Unknown Credentials Provider");
          }
        } else {
          this.transactionActive = false;
        }
      }).catch((err) => {
        console.log(err);
      });

    this.firebaseAuth.authState.subscribe(
      (user) => {
        if (user) {
          this.authorised = true;

          this.$User = this.firestore.collection("Users").doc<User>(`${user.uid}`).valueChanges().subscribe((next: User | undefined) => {
            this.User = next;
          });

          this.router.navigate(['/dashboard']);
        } else {
          this.authorised = false;

          if (this.$User) this.$User.unsubscribe();
          if (this.User)  this.User = undefined;

          this.router.navigate(['/sign-in']);
        }
      }
    );
  }

  public MicrosoftSignIn() : void {
    const provider = new firebase.auth.SAMLAuthProvider("saml.azure"); 

    this.transactionActive = true;

    this.firebaseAuth
      .signInWithRedirect(provider)
      .catch((err) => {
        console.error(err);
      });
  }

  public SignOut() : void {
    this.transactionActive = true;

    this.firebaseAuth
      .signOut()
      .then(() => {
        this.transactionActive = false;
      })
      .catch((err: any) => {
        console.log("[ERROR]", err);
        this.transactionActive = false;
      });
  }

}


