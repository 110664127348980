import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Batch } from 'src/app/models/reporting/batch';
import { DataService } from 'src/app/services/firebase/data.service';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  get sent(): boolean { return new Date(this.batch?.Scheduled || 0).getTime() > Date.now(); }

  _batch!: Subscription;
  batch!: Batch;

  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    this._batch = this.data.getBatch().subscribe(
      res => { 
        this.batch = res; 
        this.data.addLocalNav(this.batch?.Name || 'Batch', '/dashboard/batch');
      }, 
      e =>   { console.error(e); }, 
      () =>  { this.loading = false; }
    );
  }

  ngOnDestroy(): void {
    this._batch?.unsubscribe();
  }
}
